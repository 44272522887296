import cn from 'classnames';
import React, { useState } from 'react';

import { Button } from 'shared/button';
import { Form, FormItem } from 'shared/form';
import { InputNumber } from 'shared/input';

import { Hint } from 'components/hint';

import { getResponseError } from 'helpers/response';
import { validateRules } from 'helpers/validate-rules';
import { reachGoal } from 'helpers/yandex-metrika';

import { MakePaymentRequest, useMakePaymentMutation } from 'store/services/payments.service';

import styles from './make-payment.module.scss';

export interface MakePaymentProps {
  className?: string;
}

export function MakePayment({ className }: MakePaymentProps) {
  const classes = cn(styles.makePayment, className);
  const [makePayment, { isLoading }] = useMakePaymentMutation();
  const [error, setError] = useState<null | string>(null);

  const onFinish = async (values: MakePaymentRequest) => {
    setError(null);
    try {
      const { link } = await makePayment(values).unwrap();
      reachGoal('action_make_payment');
      window.location.href = link;
    } catch (error) {
      setError(getResponseError(error));
    }
  };

  return (
    <Form
      className={classes}
      onFinish={onFinish}
      autoComplete='off'
      error={error}
      actionSlot={
        <Button htmlType='submit' loading={isLoading}>
          Пополнить
        </Button>
      }
    >
      <FormItem
        name='amount'
        label={
          <>
            Сумма пополнения&nbsp;
            <Hint content={`Минимальная сумма пополнения ${AMOUNT_MIN} рублей`} />
          </>
        }
        rules={[validateRules.required('сумма')]}
      >
        <InputNumber min={AMOUNT_MIN} />
      </FormItem>
    </Form>
  );
}

const AMOUNT_MIN = 500;

