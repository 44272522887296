import cn from 'classnames';
import React, { ReactNode } from 'react';

import { routes } from 'api/routes';

import { Box } from 'components/box';
import { Container } from 'components/container';
import { ErrorBoundary } from 'components/error-boundary';
import { LinkGroup } from 'components/link-group';
import { Logo } from 'components/logo';

import { reachGoal } from 'helpers/yandex-metrika';

import styles from './auth-layout.module.scss';

export interface AuthLayoutProps {
  className?: string;
  children: ReactNode;
  footerSlot?: ReactNode;
  hideHeaderLinks?: boolean;
}

export function AuthLayout({ className, children, footerSlot, hideHeaderLinks }: AuthLayoutProps) {
  const classes = cn(styles.authLayout, className);

  return (
    <div className={classes}>
      <Container>
        <Box className={styles.box} size='middle'>
          <ErrorBoundary>
            <div className={styles.header}>
              <Logo to={routes.login()} />
              {!hideHeaderLinks && (
                <LinkGroup
                  items={[
                    {
                      to: routes.login(),
                      label: 'Авторизация',
                      onClick: () => reachGoal('go_to_login_page'),
                    },
                    {
                      to: routes.signUp(),
                      label: 'Регистрация',
                      onClick: () => reachGoal('go_to_signup_page'),
                    },
                  ]}
                />
              )}
            </div>
            {children}
            {footerSlot && <div className={styles.footer}>{footerSlot}</div>}
          </ErrorBoundary>
        </Box>
      </Container>
    </div>
  );
}

