import cn from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './link-group.module.scss';

export interface LinkGroupItemProps {
  label: string;
  to: string;
  onClick?: () => void;
}

export interface LinkGroupProps {
  className?: string;
  items: LinkGroupItemProps[];
}

export function LinkGroup({ className, items }: LinkGroupProps) {
  const classes = cn(styles.linkGroup, className);

  return (
    <div className={classes}>
      {items.map(({ label, to, onClick }) => (
        <NavLink
          className={({ isActive }) =>
            cn(styles.link, {
              [styles.link_active]: isActive,
            })
          }
          to={to}
          key={to}
          onClick={onClick}
        >
          {label}
        </NavLink>
      ))}
    </div>
  );
}

