import { QuestionCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { PageHeader } from 'shared/page-header';

import { EditableTitle } from 'components/editable-title';
import { Editor } from 'components/editor';
import { useOnboarding } from 'components/onboarding';
import { ProjectLayout } from 'components/project-layout';

import { getResponseError, checkNotFoundError } from 'helpers/response';
import { reachGoal } from 'helpers/yandex-metrika';

import { useDocumentTitle } from 'hooks/document-title';
import { useLeaveBlocker } from 'hooks/leave-blocker';
import { useProjectId } from 'hooks/project-id';

import { Project } from 'models/project';

import { NotFound } from 'features/not-found';

import { ProjectQuestions } from './components/questions';
import styles from './project.page.module.scss';
import { useEditProjectMutation, useGetProjectQuery } from './project.service';

export interface ProjectPageProps {
  className?: string;
}

export function ProjectPage({ className }: ProjectPageProps) {
  const classes = cn(styles.projectPage, className);

  const { startOnboarding } = useOnboarding();

  const { projectId } = useProjectId();

  if (projectId === undefined) {
    return <NotFound />;
  }

  const { data, isLoading, error, isError } = useGetProjectQuery(projectId);
  const [editProject, { isLoading: isSaving }] = useEditProjectMutation();
  useDocumentTitle(data?.name || '');

  const [project, setProject] = useState<Project>();

  useEffect(() => {
    if (data) {
      setProject(data);
    }
  }, [data]);

  useEffect(() => {
    const key = `notification-${Date.now}`;
    if (isError && !checkNotFoundError(error)) {
      notification.error({
        message: getResponseError(error),
        duration: 0,
        key,
      });
    }

    return () => {
      notification.close(key);
    };
  }, [isError]);

  const updateProject = <K extends keyof Project>(key: K, value: Project[K]) => {
    setProject((currentProject) => {
      if (data && currentProject) {
        const updatedProject = {
          ...currentProject,
          [key]: value,
        };
        if (project?.demo !== true) {
          compareProjects(data, updatedProject, setIsBlocking);
        }
        return updatedProject;
      }
    });
  };

  const onSave = async () => {
    if (project) {
      try {
        await editProject(project).unwrap();
        reachGoal('action_save_project');
        notification.success({
          message: 'Проект сохранен',
        });
        setIsBlocking(false);
      } catch (error) {
        notification.error({
          message: getResponseError(error),
        });
      }
    }
  };

  const { setIsBlocking } = useLeaveBlocker(onSave);

  if (checkNotFoundError(error)) {
    return <NotFound />;
  }

  return (
    <ProjectLayout
      className={classes}
      projectId={projectId}
      headerSlot={
        <EditableTitle
          isLoading={isLoading}
          to={routes.projects()}
          image={project?.image}
          onAddImage={(image) => updateProject('image', image)}
          onRemoveImage={() => updateProject('image', null)}
          title={project?.name}
          onChangeTitle={(name) => updateProject('name', name)}
          actionSlot={
            <Button data-onboarding='project-save' onClick={onSave} loading={isSaving} disabled={isLoading || isError}>
              Сохранить
            </Button>
          }
        />
      }
      containerSlot={
        <PageHeader title={'Гайд интервью'} titleLevel={4}>
          {project?.questions && (
            <ProjectQuestions
              questions={project.questions}
              onChange={(questions) => updateProject('questions', questions)}
            />
          )}
        </PageHeader>
      }
    >
      <PageHeader
        className={styles.pageHeader}
        title='Описание исследования'
        titleLevel={4}
        extra={
          project?.demo && (
            <Button type='link' icon={<QuestionCircleOutlined />} onClick={startOnboarding}>
              Как заполнять
            </Button>
          )
        }
      >
        <Editor
          rich={true}
          initialValue={project?.description}
          onChange={(description: string) => updateProject('description', description)}
          fileType='projects'
          fileId={project?.id}
        />
      </PageHeader>
    </ProjectLayout>
  );
}

const compareProjects = debounce(function (
  originalProject: Project,
  updatedProject: Project,
  setIsBlocking: (isBlocking: boolean) => void,
): void {
  setIsBlocking(!isEqual(originalProject, updatedProject));
},
750);

