import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'api/routes';

import { Avatar } from 'shared/avatar';
import { Button } from 'shared/button';
import { Space } from 'shared/space';

import { BaseMenu } from 'components/base-menu';
import { Container } from 'components/container';
import { Logo } from 'components/logo';

import { declOfNum } from 'helpers/decl-of-num';
import { reachGoal } from 'helpers/yandex-metrika';

import { useUser } from 'hooks/user';

import { Tariff } from 'features/tariff';

import styles from './header.module.scss';

export interface HeaderProps {
  className?: string;
  headerSlot?: ReactNode;
}

export function Header({ className, headerSlot }: HeaderProps) {
  const classes = cn(styles.header, className);
  const { user } = useUser();

  return (
    <div className={classes}>
      <Container className={styles.container}>
        {headerSlot ? <div className={styles.slot}>{headerSlot}</div> : <Logo to={routes.home()} />}
        {user && (
          <>
            <BaseMenu
              className={styles.menu}
              items={[
                {
                  label: 'Проекты',
                  to: routes.projects(),
                  onboardingSelector: 'menu-projects',
                },
                {
                  label: `Баланс: ${
                    typeof user.balance === 'number'
                      ? `${user.balance} ${declOfNum(user.balance, ['рубль', 'рубля', 'рублей'])}`
                      : 'неизвестно'
                  }`,
                  hideActivity: true,
                  hintContent: (
                    <Space size='middle' direction='vertical' align='center'>
                      <Tariff />
                      <Link to={routes.profile()}>
                        <Button href={routes.profile()} onClick={() => reachGoal('go_to_balance_page')}>
                          Пополнить баланс
                        </Button>
                      </Link>
                    </Space>
                  ),
                },
              ]}
            />
            <Link to={routes.profile()}>
              <Avatar src={user.image?.url} />
            </Link>
          </>
        )}
      </Container>
    </div>
  );
}

