import { PlusOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Input } from 'shared/input';
import { modalConfirm } from 'shared/modal';
import { Space } from 'shared/space';

import { declOfNum } from 'helpers/decl-of-num';
import { getResponseError } from 'helpers/response';
import { reachGoal } from 'helpers/yandex-metrika';

import { useAppDispatch, useAppSelector } from 'hooks/store';

import { createNewInterview } from 'models/interview';
import { Project } from 'models/project';

import { useAddInterviewMutation, useRemoveInterviewsMutation } from './interviews.service';
import { interviewsActions, interviewsSelector } from './interviews.slice';

const { setSelectedInterviews, setValueForFilter } = interviewsActions;

export interface InterviewActionsProps {
  projectId: Project['id'];
}

export function InterviewActions({ projectId }: InterviewActionsProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedInterviews, valueForFilter } = useAppSelector(interviewsSelector);
  const [removeInterviewsMutation] = useRemoveInterviewsMutation();
  const [addInterview, { isLoading }] = useAddInterviewMutation();

  const count = selectedInterviews.length;
  const selectedWord = declOfNum(count, ['выбранное', 'выбранные', 'выбранные']);

  const removeInterviews = async () => {
    try {
      await removeInterviewsMutation({ projectId, id: selectedInterviews.map((interview) => interview.id) }).unwrap();
      dispatch(setSelectedInterviews([]));
      notification.success({
        message: `Интервью ${declOfNum(count, ['удалено', 'удалены', 'удалены'])}`,
      });
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  const onRemoveInterviews = () => {
    modalConfirm({
      title: `Вы уверены, что хотите удалить ${selectedWord} интервью?`,
      okText: 'Удалить',
      okButtonProps: {
        danger: true,
      },
      onOk: removeInterviews,
    });
  };

  const onCreateInterview = async () => {
    try {
      const createdInterview = await addInterview({ projectId, interview: createNewInterview() }).unwrap();
      reachGoal('action_create_interview');
      navigate(routes.interview(projectId, createdInterview.id));
    } catch (error) {
      notification.error({
        message: getResponseError(error),
      });
    }
  };

  if (count === 0) {
    return (
      <Space>
        <Input
          allowClear={true}
          addonBefore={<SearchOutlined />}
          placeholder='Поиск...'
          value={valueForFilter}
          onChange={(e) => dispatch(setValueForFilter(e.target.value))}
        />
        <Button icon={<PlusOutlined />} disabled={isLoading} onClick={onCreateInterview}>
          Создать интервью
        </Button>
      </Space>
    );
  }

  return (
    <Space>
      <div>
        {declOfNum(count, ['Выбрано', 'Выбраны', 'Выбраны'])} {count} интервью
      </div>
      <Button danger={true} icon={<DeleteOutlined />} onClick={onRemoveInterviews}>
        Удалить {selectedWord}
      </Button>
    </Space>
  );
}

