import React, { useState } from 'react';

import { Input } from 'shared/input';
import { Modal } from 'shared/modal';
import { Text } from 'shared/typography';

import { reachGoal } from 'helpers/yandex-metrika';

import { WithModalProps } from 'hooks/modal';

import googleDrive from './img/googleDrive.png';
import mailCloud from './img/mailCloud.png';
import upload from './img/upload.svg';
import yandexDisk from './img/yandexDisk.png';
import styles from './interview.page.module.scss';

interface InterviewAddFileProps extends WithModalProps {
  openFileDialog: () => void;
  onCloudUpload: (link: string) => void;
  isCloudChosen: boolean;
  setIsCloudChosen: (chosen: boolean) => void;
}

export function InterviewAddFile({
  onCloudUpload,
  openFileDialog,
  open,
  toggleModal,
  isCloudChosen,
  setIsCloudChosen,
}: InterviewAddFileProps) {
  const [cloudLink, setCloudLink] = useState('');

  const onModalCancel = () => {
    reachGoal('action_close_add_file');
    toggleModal();
    setCloudLink('');
    setIsCloudChosen(false);
  };

  const onModalCancelClick = () => {
    console.log('onModalCancelClick');
    if (isCloudChosen) {
      setCloudLink('');
      setIsCloudChosen(false);
    } else {
      reachGoal('action_close_add_file');
      toggleModal();
    }
  };

  const onModalOkClick = () => {
    setCloudLink('');
    setIsCloudChosen(false);
    onCloudUpload(cloudLink);
  };

  return (
    <Modal
      width={600}
      open={open}
      onCancel={onModalCancel}
      okButtonProps={{ hidden: !isCloudChosen }}
      cancelButtonProps={{ onClick: onModalCancelClick }}
      onOk={onModalOkClick}
      okText='Загрузить'
      destroyOnClose={true}
      title='Загрузить файл'
    >
      <div className={styles.addFile}>
        {isCloudChosen && (
          <div className={styles.addFile__cloud}>
            <InterviewFileClouds />
            <Text>Сейчас мы поддерживаем только: Яндекс диск, Google Drive, Облако Mail.ru</Text>
            <Input
              placeholder='Вставьте ссылку из облака'
              value={cloudLink}
              onChange={(e) => setCloudLink(e.currentTarget.value)}
            />
          </div>
        )}

        {!isCloudChosen && (
          <>
            <button className={styles.addFile__left} onClick={openFileDialog}>
              <div className={styles.addFile__top}>
                <Text>Загрузите файл со своего устройства</Text>
              </div>
              <div className={styles.addFile__img}>
                <img src={upload} />
              </div>
            </button>
            <Text className={styles.addFile__or}>или</Text>
            <button className={styles.addFile__right} onClick={() => setIsCloudChosen(true)}>
              <div className={styles.addFile__top}>
                <Text>Вставьте ссылку из облака</Text>
              </div>
              <InterviewFileClouds />
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

function InterviewFileClouds() {
  return (
    <div className={styles.addFile__clouds}>
      <div className={styles.addFile__img}>
        <img src={googleDrive} alt='Иконка Google Drive' />
      </div>
      <div className={styles.addFile__img}>
        <img src={yandexDisk} alt='Иконка Яндекс диск' />
      </div>
      <div className={styles.addFile__img}>
        <img src={mailCloud} alt='Иконка Облако Mail.ru' />
      </div>
    </div>
  );
}

