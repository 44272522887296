declare global {
  interface Window {
    ym?: (counderId: number, name: 'reachGoal', goalId: string) => void;
  }
}

const COUNTER_ID = 87323212;

export function reachGoal(id: string) {
  window.ym?.(COUNTER_ID, 'reachGoal', id);
}

